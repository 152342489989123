import cx from 'classnames'

import { SanityGrid, SanityColor } from '@data/sanity/queries/types/modules'
import { getGridSizeClass, getItemOrderClass } from '@lib/dom'

import GridBlock from './grid-block'

type GridProps = SanityGrid

type Spacing = SanityGrid['spacing']

type ColorClassMap = Record<SanityColor, string | string[]>

const gridColorClasses: ColorClassMap = {
  [SanityColor.WHITE]: 'bg-white text-black',
  [SanityColor.BLACK]: 'bg-black text-white',
}

const getSpacingClasses = (
  spacing: Spacing,
  type: 'padding' | 'margin',
  side?: 'top' | 'right' | 'bottom' | 'left'
) => {
  const t = type.charAt(0)
  const s = spacing?.[type]

  const top = {
    [`${t}t-${s?.top}`]: s?.top,
    [`sm:${t}t-${s?.smTop}`]: s?.smTop,
    [`md:${t}t-${s?.mdTop}`]: s?.mdTop,
    [`lg:${t}t-${s?.lgTop}`]: s?.lgTop,
  }

  const right = {
    [`${t}r-${s?.right}`]: s?.right,
    [`sm:${t}r-${s?.smRight}`]: s?.smRight,
    [`md:${t}r-${s?.mdRight}`]: s?.mdRight,
    [`lg:${t}r-${s?.lgRight}`]: s?.lgRight,
  }

  const bottom = {
    [`${t}b-${s?.bottom}`]: s?.bottom,
    [`sm:${t}b-${s?.smBottom}`]: s?.smBottom,
    [`md:${t}b-${s?.mdBottom}`]: s?.mdBottom,
    [`lg:${t}b-${s?.lgBottom}`]: s?.lgBottom,
  }

  const left = {
    [`${t}l-${s?.left}`]: s?.left,
    [`sm:${t}l-${s?.smLeft}`]: s?.smLeft,
    [`md:${t}l-${s?.mdLeft}`]: s?.mdLeft,
    [`lg:${t}l-${s?.lgLeft}`]: s?.lgLeft,
  }

  if (side === 'top') {
    return top
  }

  if (side === 'right') {
    return right
  }

  if (side === 'bottom') {
    return bottom
  }

  if (side === 'left') {
    return left
  }

  return {
    ...top,
    ...right,
    ...bottom,
    ...left,
  }
}

const Grid = ({
  size,
  spacing,
  columns: rawColumns,
  reverseSequence,
  background,
  noColumnGaps,
  noRowGaps,
}: GridProps) => {
  const columns = rawColumns?.map((column, columnIndex) => {
    if (!column.sizes) {
      return {
        ...column,
        classes: [],
      }
    }

    const columnClasses = column.sizes.map(
      ({ breakpoint, width, justify, align, start }) =>
        getGridSizeClass(breakpoint, width, justify, align, start)
    )
    const orderClasses = column.sizes.map(({ breakpoint, width }) =>
      getItemOrderClass(columnIndex, breakpoint, width, size, reverseSequence)
    )

    return {
      ...column,
      classes: [...columnClasses, ...orderClasses],
    }
  })

  return (
    <section
      className={cx(
        `${background ? gridColorClasses[background] : ''}`,
        getSpacingClasses(spacing, 'margin')
      )}
    >
      <div
        className={cx(
          'relative container',
          `pt-${spacing?.padding?.top ?? 12} pb-${
            spacing?.padding?.bottom ?? 12
          }`,
          `sm:pt-${spacing?.padding?.smTop ?? 16} sm:pb-${
            spacing?.padding?.smBottom ?? 16
          }`,
          `md:pt-${spacing?.padding?.mdTop ?? 24} md:pb-${
            spacing?.padding?.mdBottom ?? 24
          }`,
          `lg:pt-${spacing?.padding?.lgTop ?? 32} lg:pb-${
            spacing?.padding?.lgBottom ?? 32
          }`,
          getSpacingClasses(spacing, 'padding', 'right'),
          getSpacingClasses(spacing, 'padding', 'left')
        )}
      >
        <div className="mx-auto">
          <div
            className={cx(
              `grid grid-cols-${size} gap-x-4 gap-y-8 sm:gap-x-8 lg:gap-x-12 lg:gap-y-12`,
              {
                '!gap-x-0': noColumnGaps,
                '!gap-y-0': noRowGaps,
              }
            )}
          >
            {columns?.map(({ _key, blocks, classes, spacing }) => (
              <div
                key={_key}
                className={cx(
                  classes,
                  getSpacingClasses(spacing, 'margin'),
                  getSpacingClasses(spacing, 'padding')
                )}
              >
                {blocks?.map((block) => (
                  <GridBlock key={block._key} block={block} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
